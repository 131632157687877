export const contactmember_types = {
    PERSOENLICH:0,
    TELEFON:1,
    EMAIL:2,
    SONSTIGES:3,
    MAX:4
}

export const contactmember_state = {
    NEWCONTACT:0,
    INWORK:1,
    CLOSED:2,
    ANONYM:3,
    MAX:4
}

export const contactmember_reason = {
    BERATUNG:0,
    RUECKFRAGE:1,
    LIEFERUNG:2,
    REKLAMATION:3,
    SERVICE:4,
    SONSTIGES:5,
    MAX:6
}

export const documentType = {
    UNKNOWN: 0,
    PRICELIST: 1,
    OFFER:2,
    INVOICE:3,
    ORDER:4,
    DELIVERY:5,
    AB:6,  
    PARTNERORDER:7,
    STORNO:8,
    GUTSCHRIFT:9,
    TIMEREC:10,
    CONTACT:11,
    DEBITORDATAALL:12,
    MAX:13
}

export const documentStatus = {
    UNKNOWN: 0,
    REQUESTED: 1,
    INPROGRESS: 2,
    FINISHED: 3,
    OUTDATED:4,
    NOTFOUND:5,
    ERROR:6,
    MAX: 7
}

export const pricelistBlock = {
    UNKNOWN:0,
    STD_PRINT:1,
    RAL:2,
    ZUBEHOER:3,
    WERBE:4,
    FOOTER:5,
    SONDERMONTAGE:6,
    THERMOSTATE:7,
    MAX:8
}

export const debitorObjectStructure = {
    ZIEGEL:0,
    BETON:1,
    DURISOL:2,
    YTONG:3,
    HOLZMASSIV:4,
    HOLZRIEGEL:5,
    MAX:6
}

export const debitorObjectType = {
    EINFAMILIE:0,
    REIHEN:1,
    WOHNUNG:2,
    MEHRPARTEIEN:3,
    MAX:4
}

export const debitorObjectNeeds = {
    NEUBAU:0,
    UMBAU:1,
    RENOVIERUNG:2,
    ZUSATZHEIZUNG:3,
    PUNKTUELL:4,
    MAX:5
}

export const debitorObjectProblems = {
    SCHIMMEL:0,
    FEUCHTIGKEIT:1,
    KAELTEEMPFINDEN:2,
    MAX:3
}

export const numbergroup_Types = {
    ARTIKEL:0,
    ANGEBOT:1,
    RECHNUNG:2,
    LIEFERSCHEIN:3,
    AUFTRAG:4,
    KUNDE:5,
    LIEFERANT:6,
    AB:7,
    BESTELLUNG:8,
    MAX:9
}

export const commonStates = {
    CREATED:0,
    OPEN:1,
    CLOSED:2,
    DELETED:3,
    PAYED:4,
    INWORK:5,
    WAITING:6,
    LOCKED:7,
    ORDERED:8,
    CLOSEDBUTNOTFINISHED:9,
    FINISHED:10,
    OFFERED:11,
    SENT:12,  
    STORNO:13,
    CONFIRMED:14,
    REJECTED:15,
    MAX:16
}

export const autoMemberType =
{
    NONE:0,
    MANUAL:1,
    RALCOLORSTD:2,
    RALCOLORNEW:3,
    DELIVERYCONDITION:4,
    MAX:5
}

export const invoiceTypes = 
{
    GESAMT:0,
    ANZAHLUNG:1,
    TEIL:2,
    SCHLUSS:3,
    STORNO:4,
    GUTSCHRIFT:5,
    MAX:6
}

export const paymentType = 
{
    INCOMING:0,
    OUTGOING:1,
    MAX:2
}

export const discountType =
{
    SKONTO:0,
    RABATT:1,
    MAX:2
}

export const shipmentStatus = 
{
    CREATED:0,
    PREPARED:1,
    ONTHEWAY:2,
    DELIVERED:3,
    LOST:4,
    RETURNED:5,
    CANCELED:6,
    ONTHEWAYBACK:7,
    MAX:8
}

export const shipmentOwnerType =
{
    ORDER:0,
    PARTNERORDER:1,
    MAX:2
}

export const companyGroupType =
{
    UNDEFINED:0,
    HERSTELLER:1,
    ZENTRALE:2,
    VERTRIEBSPARTNER:3,
    HAENDLER:4,
    MAX:5
}

export const productVariantExtraOptions =
{
    NONE:0,
    RALPICKER:1,
    MOTIFPICKER:2,
    PRODUCTPICKER:3,
    MAX:4
}

export const orientation =
{
    HORIZONTAL:0,
    VERTICAL:1,
    MAX:2
}

export const pricelistMemberZusatzinfoType =
{
    NONE:0,
    RALSTANDARD:1,
    RALCARD:2,
    MAX:3
}

export const timeRecType =
{
    TIMEREC:0,
    PAUSE:1,
    VACATION:2,
    HOLIDAY:3,
    SPECIAL:4,
    ILL:5,
    DOCTOR:6,
    OTHERS:7,
    MAX:8
}

export const searchResultType =
{
    DEBITOR:0,
    DEBITOR_CONTACT:1,
    DEBITOR_OBJ:2,
    CREDITOR:3,
    USER:4,
    OFFER:5,
    ORDER:6,
    INVOICE:7,
    PAYMENT:8,
    MAX:9
}

export const inputSelectType =
{
    DEBITOROBJECTSTRUCTURE:0,
    DEBITOROBJECTTYPE:1,
    DEBITOROBJECTNEEDS:2,
    DEBITOROBJECTPROBLEMS:3,
    CONTACTREASON:4,
    CONTACTTYPE:5,
    ROOMAUSSEN:6,
    ROOMDECKE:7,
    ROOMBODEN:8,
    ROOMFENSTER:9,
    ROOMINNEN:10,
    OFFERDESCRIPTION:11,
    MAX:12
}

export const shipmentMergeType =
{
    EQUAL:0,
    EQUALPROD:1,
    MAX:2
}

export default {

    isEditOfferAllowed(state)
    {
        var retVal = false;
        if(typeof(state) != 'undefined')
        {
            retVal = state == commonStates.CREATED || state == commonStates.INWORK;
        }
        return retVal;
    },
    isCreateOrderFromOfferAllowed(state)
    {
        var retVal = false;
        if(typeof(state) != 'undefined')
        {
            retVal = state == commonStates.CREATED || state == commonStates.INWORK || state == commonStates.CONFIRMED;
        }
        return retVal;
    },
    isOrderOfferAllowed(state)
    {
        var retVal = false;
        if(typeof(state) != 'undefined')
        {
            retVal = state == commonStates.CREATED || state == commonStates.INWORK || state == commonStates.CONFIRMED;
        }
        return retVal;
    },
    isEditOrderAllowed(state)
    {
        var retVal = false;
        if(typeof(state) != 'undefined')
        {
            retVal = state == commonStates.CREATED || state == commonStates.INWORK || state == commonStates.OPEN;
        }
        return retVal;
    },
    isEditPartnerOrderAllowed(state)
    {
        var retVal = false;
        if(typeof(state) != 'undefined')
        {
            retVal = state == commonStates.CREATED || state == commonStates.INWORK || state == commonStates.OPEN;
        }
        return retVal;
    },
    GetProductVariantExtraOptionsForSelect()
    {
        var ret = [];
        ret.push({"text": "- - - - -", "value":-1});
        for(var i = 0; i < productVariantExtraOptions.MAX; i++)
        {
            ret.push({"text": this.GetProductVariantExtraOptionsText(i), "value":i});
        }
        return ret;
    },
    GetPricelistMemberZusatzinfoTypeForSelect()
    {
        var ret = [];
        ret.push({"text": "- - - - -", "value":-1});
        for(var i = 0; i < pricelistMemberZusatzinfoType.MAX; i++)
        {
            ret.push({"text": this.GetPricelistMemberZusatzinfoTypeText(i), "value":i});
        }
        return ret;
    },
    GetNumbergroupTypesForSelect()
    {
        var ret = [];
        ret.push({"text": "- - - - -", "value":-1});
        for(var i = 0; i < numbergroup_Types.MAX; i++)
        {
            ret.push({"text": this.GetNumbergroupTypesText(i), "value":i});
        }
        return ret;
    },
    GetDebitorObjectStructureForSelect()
    {
        var ret = [];
        for(var i = 0; i < debitorObjectStructure.MAX; i++)
        {
            ret.push({"text": this.GetDebitorObjectStructureText(i), "value":i});
        }
        return ret;
    },
    GetDebitorObjectTypeForSelect()
    {
        var ret = [];
        for(var i = 0; i < debitorObjectType.MAX; i++)
        {
            ret.push({"text": this.GetDebitorObjectTypeText(i), "value":i});
        }
        return ret;
    },   
    GetDebitorObjectNeedsForSelect()
    {
        var ret = [];
        for(var i = 0; i < debitorObjectNeeds.MAX; i++)
        {
            ret.push({"text": this.GetDebitorObjectNeedsText(i), "value":i});
        }
        return ret;
    }, 
    GetDebitorObjectProblemsForSelect()
    {
        var ret = [];
        for(var i = 0; i < debitorObjectProblems.MAX; i++)
        {
            ret.push({"text": this.GetDebitorObjectProblemsText(i), "value":i});
        }
        return ret;
    },      
    GetDiscountTypesForSelect(firstempty)
    {
        var addFirst = true;
        if(typeof(firstempty) != "undefined")
        {
            addFirst = firstempty;
        }
        var ret = [];
        if(addFirst)
        {
            ret.push({"text": "- - - - -", "value":-1});
        }
        for(var i = 0; i < discountType.MAX; i++)
        {
            ret.push({"text": this.GetDiscountTypesText(i), "value":i});
        }
        return ret;
    },
    GetShipmentStatusForDropDown()
    {
        var ret = [];
        for(var i = 0; i < shipmentStatus.MAX; i++)
        {
            ret.push({"text": this.GetShipmentStatusText(i), "value":i});
        }
        return ret;
    },
    GetCompanyGroupTypeForDropDown()
    {
        var ret = [];
        for(var i = 0; i < companyGroupType.MAX; i++)
        {
            ret.push({"text": this.GetCompanyGroupTypeText(i), "value":i});
        }
        return ret;
    },    

    GetNumbergroupTypesText(val)
    {
        var retVal = "";
        switch(val)
        {
            case numbergroup_Types.ARTIKEL: 
                retVal = this.$t('ENUMS.NUMBERGROUPS.ARTIKEL');
                break;
            case numbergroup_Types.ANGEBOT: 
                retVal = this.$t('ENUMS.NUMBERGROUPS.ANGEBOT');
                break;
            case numbergroup_Types.AUFTRAG: 
                retVal = this.$t('ENUMS.NUMBERGROUPS.AUFTRAGSBESTAETIGUNG');
                break;    
            case numbergroup_Types.KUNDE: 
                retVal = this.$t('ENUMS.NUMBERGROUPS.KUNDE');
                break;  
            case numbergroup_Types.LIEFERANT: 
                retVal = this.$t('ENUMS.NUMBERGROUPS.LIEFERANT');
                break;   
            case numbergroup_Types.LIEFERSCHEIN: 
                retVal = this.$t('ENUMS.NUMBERGROUPS.LIEFERSCHEIN');
                break;  
            case numbergroup_Types.RECHNUNG: 
                retVal = this.$t('ENUMS.NUMBERGROUPS.RECHNUNG');
                break; 
            case numbergroup_Types.AB: 
                retVal = this.$t('ENUMS.NUMBERGROUPS.AB');
                break;     
            case numbergroup_Types.BESTELLUNG: 
                retVal = this.$t('ENUMS.NUMBERGROUPS.BESTELLUNG');
                break;                
                                                                                                                                                      
        }
        return retVal;
    },
    GetDebitorObjectProblemsText(val)
    {
        var retVal = "";
        switch(val)
        {
            case debitorObjectProblems.SCHIMMEL: 
                retVal = this.$t('ENUMS.DEBITOROBJECT.PROBLEMS_SCHIMMEL');
                break;
            case debitorObjectProblems.FEUCHTIGKEIT: 
                retVal = this.$t('ENUMS.DEBITOROBJECT.PROBLEMS_FEUCHTIGKEIT');
                break;
            case debitorObjectProblems.KAELTEEMPFINDEN: 
                retVal = this.$t('ENUMS.DEBITOROBJECT.PROBLEMS_KAELTEEMPFINDEN');
                break;                                                                              
        }
        return retVal;
    },
    GetDebitorObjectNeedsText(val)
    {
        var retVal = "";
        switch(val)
        {
            case debitorObjectNeeds.NEUBAU: 
                retVal = this.$t('ENUMS.DEBITOROBJECT.NEEDS_NEUBAU');
                break;
            case debitorObjectNeeds.UMBAU: 
                retVal = this.$t('ENUMS.DEBITOROBJECT.NEEDS_UMBAU');
                break;
            case debitorObjectNeeds.RENOVIERUNG: 
                retVal = this.$t('ENUMS.DEBITOROBJECT.NEEDS_RENOVIERUNG');
                break;   
            case debitorObjectNeeds.ZUSATZHEIZUNG: 
                retVal = this.$t('ENUMS.DEBITOROBJECT.NEEDS_ZUSATZHEIZUNG');
                break; 
            case debitorObjectNeeds.PUNKTUELL: 
                retVal = this.$t('ENUMS.DEBITOROBJECT.NEEDS_PUNKTUELL');
                break;                                                                               
        }
        return retVal;
    },
    GetDebitorObjectTypeText(val)
    {
        var retVal = "";
        switch(val)
        {
            case debitorObjectType.EINFAMILIE: 
                retVal = this.$t('ENUMS.DEBITOROBJECT.TYPE_EINFAMILIE');
                break;
            case debitorObjectType.REIHEN: 
                retVal = this.$t('ENUMS.DEBITOROBJECT.TYPE_REIHEN');
                break;
            case debitorObjectType.WOHNUNG: 
                retVal = this.$t('ENUMS.DEBITOROBJECT.TYPE_WOHNUNG');
                break;   
            case debitorObjectType.MEHRPARTEIEN: 
                retVal = this.$t('ENUMS.DEBITOROBJECT.TYPE_MEHRPARTEIEN');
                break;                                                                               
        }
        return retVal;
    },
    GetDebitorObjectStructureText(val)
    {
        var retVal = "";
        switch(val)
        {
            case debitorObjectStructure.ZIEGEL: 
                retVal = this.$t('ENUMS.DEBITOROBJECT.STRUCTURE_ZIEGEL');
                break;
            case debitorObjectStructure.BETON: 
                retVal = this.$t('ENUMS.DEBITOROBJECT.STRUCTURE_BETON');
                break;
            case debitorObjectStructure.DURISOL: 
                retVal = this.$t('ENUMS.DEBITOROBJECT.STRUCTURE_DURISOL');
                break;   
            case debitorObjectStructure.YTONG: 
                retVal = this.$t('ENUMS.DEBITOROBJECT.STRUCTURE_YTONG');
                break;  
            case debitorObjectStructure.HOLZMASSIV: 
                retVal = this.$t('ENUMS.DEBITOROBJECT.STRUCTURE_HOLZMASSIV');
                break; 
            case debitorObjectStructure.HOLZRIEGEL: 
                retVal = this.$t('ENUMS.DEBITOROBJECT.STRUCTURE_HOLZRIEGEL');
                break;                                                                                
        }
        return retVal;
    },
    GetContactMemberTypeText(stat)
    {
        var retVal = "";
        switch(stat)
        {
            case contactmember_types.PERSOENLICH: 
                retVal = this.$t('ENUMS.CONTACTMEMBER.TYPES_PERSOENLICH');
                break;
            case contactmember_types.TELEFON: 
                retVal = this.$t('ENUMS.CONTACTMEMBER.TYPES_TELEFON');
                break;
            case contactmember_types.EMAIL: 
                retVal = this.$t('ENUMS.CONTACTMEMBER.TYPES_EMAIL');
                break;   
            case contactmember_types.SONSTIGES: 
                retVal = this.$t('ENUMS.CONTACTMEMBER.TYPES_SONSTIGES');
                break;                                                 
        }
        return retVal;
    },
    GetCommonStateText(state)
    {
        var retVal = "";
        switch(state)
        {
            case commonStates.CREATED:
                retVal = this.$t('ENUMS.COMMONSTATE.CREATED');
                break;
            case commonStates.OPEN:
                retVal = this.$t('ENUMS.COMMONSTATE.OPEN');
                break;
            case commonStates.CLOSED:
                retVal = this.$t('ENUMS.COMMONSTATE.CLOSED');
                break;
            case commonStates.DELETED:
                retVal = this.$t('ENUMS.COMMONSTATE.DELETED');
                break;
            case commonStates.PAYED:
                retVal = this.$t('ENUMS.COMMONSTATE.PAYED');
                break;
            case commonStates.INWORK:
                retVal = this.$t('ENUMS.COMMONSTATE.INWORK');
                break;
            case commonStates.WAITING:
                retVal = this.$t('ENUMS.COMMONSTATE.WAITING');
                break; 
            case commonStates.LOCKED:
                retVal = this.$t('ENUMS.COMMONSTATE.LOCKED');
                break;  
            case commonStates.ORDERED:
                retVal = this.$t('ENUMS.COMMONSTATE.ORDERED');
                break;     
            case commonStates.CLOSEDBUTNOTFINISHED:
                retVal = this.$t('ENUMS.COMMONSTATE.CLOSEDBUTNOTFINISHED');
                break;                             
            case commonStates.FINISHED:
                retVal = this.$t('ENUMS.COMMONSTATE.FINISHED');
                break; 
            case commonStates.SENT:
                retVal = this.$t('ENUMS.COMMONSTATE.SENT');
                break; 
            case commonStates.OFFERED:
                retVal = this.$t('ENUMS.COMMONSTATE.OFFERED');
                break;   
            case commonStates.STORNO:
                retVal = this.$t('ENUMS.COMMONSTATE.STORNO');
                break; 
            case commonStates.CONFIRMED:
                retVal = this.$t('ENUMS.COMMONSTATE.CONFIRMED');
                break;  
            case commonStates.REJECTED:
                retVal = this.$t('ENUMS.COMMONSTATE.REJECTED');
                break;                                                                                                                                                                                                  
        }
        return retVal;
    },   
    GetCommonStateColor(state)
    {
        var retVal = "";
        switch(state)
        {
            case commonStates.CREATED:
                retVal = "primary";
                break;
            case commonStates.OPEN:
                retVal = "success";
                break;
            case commonStates.CLOSED:
                retVal = "danger";
                break;
            case commonStates.DELETED:
                retVal = "dark";
                break;
            case commonStates.PAYED:
                retVal = "dark";
                break;
            case commonStates.INWORK:
                retVal = "dark";
                break;
            case commonStates.WAITING:
                retVal = "warning";
                break; 
            case commonStates.LOCKED:
                retVal = "dark";
                break; 
            case commonStates.ORDERED:
                retVal = "orange";
                break;  
            case commonStates.CLOSEDBUTNOTFINISHED:
                retVal = "warning";
                break; 
            case commonStates.FINISHED:
                retVal = "success";
                break;
            case commonStates.OFFERED:
                retVal = "darkred";
                break; 
            case commonStates.SENT:
                retVal = "primary";
                break; 
            case commonStates.STORNO:
                retVal = "gray";
                break; 
            case commonStates.CONFIRMED:
                retVal = "success";
                break;  
            case commonStates.REJECTED:
                retVal = "danger";
                break;                                                                                                                                                                                                                                                                                     
        }
        return retVal;
    },     
    GetContactMemberStateText(stat)
    {
        var retVal = "";
        switch(stat)
        {
            case contactmember_state.NEWCONTACT: 
                retVal = this.$t('ENUMS.CONTACTMEMBER.STATE_NEWCONTACT');
                break;
            case contactmember_state.INWORK: 
                retVal = this.$t('ENUMS.CONTACTMEMBER.STATE_INWORK');
                break;
            case contactmember_state.CLOSED: 
                retVal = this.$t('ENUMS.CONTACTMEMBER.STATE_CLOSED');
                break;  
            case contactmember_state.ANONYM: 
                retVal = this.$t('ENUMS.CONTACTMEMBER.STATE_ANONYM');
                break;                                                 
        }
        return retVal;
    },
    GetContactMemberStateColor(stat)
    {
        var retVal = "";
        switch(stat)
        {
            case contactmember_state.NEWCONTACT: 
                retVal = "primary";
                break;
            case contactmember_state.INWORK: 
                retVal = "";
                break;
            case contactmember_state.CLOSED: 
                retVal = "dark";
                break;  
            case contactmember_state.ANONYM: 
                retVal = "warning";
                break;                                                  
        }
        return retVal;
    },
    GetContactMemberReasonText(stat)
    {
        var retVal = "";
        switch(stat)
        {
            case contactmember_reason.BERATUNG: 
                retVal = this.$t('ENUMS.CONTACTMEMBER.REASON_BERATUNG');
                break;
            case contactmember_reason.RUECKFRAGE: 
                retVal = this.$t('ENUMS.CONTACTMEMBER.REASON_RUECKFRAGE');
                break;
            case contactmember_reason.LIEFERUNG: 
                retVal = this.$t('ENUMS.CONTACTMEMBER.REASON_LIEFERUNG');
                break; 
            case contactmember_reason.REKLAMATION: 
                retVal = this.$t('ENUMS.CONTACTMEMBER.REASON_REKLAMATION');
                break;    
            case contactmember_reason.SERVICE: 
                retVal = this.$t('ENUMS.CONTACTMEMBER.REASON_SERVICE');
                break;      
            case contactmember_reason.SONSTIGES: 
                retVal = this.$t('ENUMS.CONTACTMEMBER.REASON_SONSTIGES');
                break;                                                                            
        }
        return retVal;
    },
    GetListOfContactMemberTypeForSelect(){

        var ret = [];
        for(var i = 0; i < contactmember_types.MAX; i++)
        {
            ret.push({"text": this.GetContactMemberTypeText(i), "value":i});
        }
        return ret;
    },
    GetListOfContactMemberReasonForSelect(){

        var ret = [];
        for(var i = 0; i < contactmember_reason.MAX; i++)
        {
            ret.push({"text": this.GetContactMemberReasonText(i), "value":i});
        }
        return ret;
    },
    GetDokumentStatusText(stat)
    {
        var retVal = "";
        switch(stat)
        {
            case documentStatus.UNKNOWN: 
                retVal = this.$t('DOCUMENTS.STATUS_UNKNOWN');
                break;
            case documentStatus.REQUESTED: 
                retVal = this.$t('DOCUMENTS.STATUS_REQUESTED');
                break;
            case documentStatus.INPROGRESS: 
                retVal = this.$t('DOCUMENTS.STATUS_INPROGRESS');
                break;    
            case documentStatus.FINISHED: 
                retVal = this.$t('DOCUMENTS.STATUS_FINISHED');
                break; 
            case documentStatus.OUTDATED: 
                retVal = this.$t('DOCUMENTS.STATUS_OUTDATED');
                break;  
            case documentStatus.NOTFOUND: 
                retVal = this.$t('DOCUMENTS.STATUS_NOTFOUND');
                break; 
            case documentStatus.ERROR: 
                retVal = this.$t('DOCUMENTS.STATUS_ERROR');
                break;                
                
        }
        return retVal;
    },
    GetPricelistBlockText(stat)
    {
        var retVal = "";
        switch(stat)
        {
            case pricelistBlock.UNKNOWN: 
                retVal = this.$t('PRICELISTS.BLOCK_UNKNOWN');
                break;
            case pricelistBlock.STD_PRINT: 
                retVal = this.$t('PRICELISTS.BLOCK_STD_PRINT');
                break;
            case pricelistBlock.RAL: 
                retVal = this.$t('PRICELISTS.BLOCK_RAL');
                break;    
            case pricelistBlock.WERBE: 
                retVal = this.$t('PRICELISTS.BLOCK_WERBE');
                break; 
            case pricelistBlock.ZUBEHOER: 
                retVal = this.$t('PRICELISTS.BLOCK_ZUBEHOER');
                break;  
            case pricelistBlock.FOOTER: 
                retVal = this.$t('PRICELISTS.BLOCK_FOOTER');
                break; 
            case pricelistBlock.SONDERMONTAGE: 
                retVal = this.$t('PRICELISTS.BLOCK_SONDERMONTAGE');
                break; 
            case pricelistBlock.THERMOSTATE: 
                retVal = this.$t('PRICELISTS.BLOCK_THERMOSTATE');                                
                break;                                                                                
        }
        return retVal; 
    },
    GetDokumentTypeText(type)
    {
        var retVal = "";
        switch(type)
        {
            case documentType.UNKNOWN: 
                retVal = this.$t('DOCUMENTS.TYPE_UNKNOWN');
                break;
            case documentType.PRICELIST: 
                retVal = this.$t('DOCUMENTS.TYPE_PRICELIST');
                break;       
            case documentType.OFFER: 
                retVal = this.$t('DOCUMENTS.TYPE_OFFER');
                break;     
            case documentType.INVOICE: 
                retVal = this.$t('DOCUMENTS.TYPE_INVOICE');
                break;    
            case documentType.ORDER: 
                retVal = this.$t('DOCUMENTS.TYPE_ORDER');
                break;
            case documentType.DELIVERY: 
                retVal = this.$t('DOCUMENTS.TYPE_DELIVERY');
                break;   
            case documentType.AB: 
                retVal = this.$t('DOCUMENTS.TYPE_AB');
                break;  
            case documentType.PARTNERORDER: 
                retVal = this.$t('DOCUMENTS.TYPE_PARTNERORDER');
                break;  
            case documentType.STORNO: 
                retVal = this.$t('DOCUMENTS.TYPE_STORNO');
                break;  
            case documentType.GUTSCHRIFT: 
                retVal = this.$t('DOCUMENTS.TYPE_GUTSCHRIFT');
                break;     
            case documentType.TIMEREC: 
                retVal = this.$t('DOCUMENTS.TYPE_TIMEREC');
                break;   
            case documentType.CONTACT: 
                retVal = this.$t('DOCUMENTS.TYPE_CONTACT');
                break; 
            case documentType.DEBITORDATAALL:
                retVal = this.$t('DOCUMENTS.TYPE_DEBITORDATAALL');
                break;                                                                                                                                       
        }
        return retVal;
    },
    GetListOfDokumentTypeForSelect(){

        var ret = [];
        ret.push({"text": "- - - - -", "value":-1});
        for(var i = 0; i < documentType.MAX; i++)
        {
            ret.push({"text": this.GetDokumentTypeText(i), "value":i});
        }
        return ret;
    },
    GetListOfPricelistBlocksForSelect(){

        var ret = [];
        ret.push({"text": "- - - - -", "value":-1});
        for(var i = 0; i < pricelistBlock.MAX; i++)
        {
            ret.push({"text": this.GetPricelistBlockText(i), "value":i});
        }
        return ret;
    },
    GetInvoiceTypeText(stat)
    {
        var retVal = "";
        switch(stat)
        {
            case invoiceTypes.GESAMT: 
                retVal = this.$t('ENUMS.INVOICE.TYPE.GESAMT');
                break;
            case invoiceTypes.ANZAHLUNG: 
                retVal = this.$t('ENUMS.INVOICE.TYPE.ANZAHLUNG');
                break;
            case invoiceTypes.SCHLUSS: 
                retVal = this.$t('ENUMS.INVOICE.TYPE.SCHLUSS');
                break;   
            case invoiceTypes.TEIL: 
                retVal = this.$t('ENUMS.INVOICE.TYPE.TEIL');
                break;    
            case invoiceTypes.STORNO: 
                retVal = this.$t('ENUMS.INVOICE.TYPE.STORNO');
                break;                                                              
            case invoiceTypes.GUTSCHRIFT: 
                retVal = this.$t('ENUMS.INVOICE.TYPE.GUTSCHRIFT');
                break;                 
        }
        return retVal;
    },
    GetDiscountTypesText(stat)
    {
        var retVal = "";
        switch(stat)
        {
            case discountType.SKONTO: 
                retVal = this.$t('ENUMS.DISCOUNTTYPE.SKONTO');
                break;
            case discountType.RABATT: 
                retVal = this.$t('ENUMS.DISCOUNTTYPE.RABATT');
                break;                                               
        }
        return retVal;
    },
    GetShipmentStatusText(stat)
    {
        var retVal = "";
        switch(stat)
        {
            case shipmentStatus.CREATED: 
                retVal = this.$t('ENUMS.SHIPMENTSTATUS.CREATED');
                break;
            case shipmentStatus.PREPARED: 
                retVal = this.$t('ENUMS.SHIPMENTSTATUS.PREPARED');
                break;  
            case shipmentStatus.ONTHEWAY: 
                retVal = this.$t('ENUMS.SHIPMENTSTATUS.ONTHEWAY');
                break; 
            case shipmentStatus.DELIVERED: 
                retVal = this.$t('ENUMS.SHIPMENTSTATUS.DELIVERED');
                break; 
            case shipmentStatus.LOST: 
                retVal = this.$t('ENUMS.SHIPMENTSTATUS.LOST');
                break; 
            case shipmentStatus.RETURNED: 
                retVal = this.$t('ENUMS.SHIPMENTSTATUS.RETURNED');
                break; 
            case shipmentStatus.CANCELED: 
                retVal = this.$t('ENUMS.SHIPMENTSTATUS.CANCELED');
                break;     
            case shipmentStatus.ONTHEWAYBACK: 
                retVal = this.$t('ENUMS.SHIPMENTSTATUS.ONTHEWAYBACK');
                break;                                                                                                                                         
        }
        return retVal;
    },
    GetCompanyGroupTypeText(stat)
    {
        var retVal = "";
        switch(stat)
        {
            case companyGroupType.UNDEFINED: 
                retVal = this.$t('ENUMS.COMPANYGROUPTYPE.UNDEFINED');
                break;
            case companyGroupType.HERSTELLER: 
                retVal = this.$t('ENUMS.COMPANYGROUPTYPE.HERSTELLER');
                break;    
            case companyGroupType.ZENTRALE: 
                retVal = this.$t('ENUMS.COMPANYGROUPTYPE.ZENTRALE');
                break; 
            case companyGroupType.VERTRIEBSPARTNER: 
                retVal = this.$t('ENUMS.COMPANYGROUPTYPE.VERTRIEBSPARTNER');
                break;  
            case companyGroupType.HAENDLER: 
                retVal = this.$t('ENUMS.COMPANYGROUPTYPE.HAENDLER');
                break;                                                                                           
        }
        return retVal;
    },
    GetProductVariantExtraOptionsText(stat)
    {
        var retVal = "";
        switch(stat)
        {
            case productVariantExtraOptions.NONE: 
                retVal = this.$t('ENUMS.PRODUCTVARIANTEXTRAOPTIONS.NONE');
                break;
            case productVariantExtraOptions.RALPICKER: 
                retVal = this.$t('ENUMS.PRODUCTVARIANTEXTRAOPTIONS.RALPICKER');
                break; 
            case productVariantExtraOptions.MOTIFPICKER: 
                retVal = this.$t('ENUMS.PRODUCTVARIANTEXTRAOPTIONS.MOTIFPICKER');
                break;   
            case productVariantExtraOptions.PRODUCTPICKER: 
                retVal = this.$t('ENUMS.PRODUCTVARIANTEXTRAOPTIONS.PRODUCTPICKER');
                break;                                                                                                         
        }
        return retVal;
    },
    GetPricelistMemberZusatzinfoTypeText(stat)
    {
        var retVal = "";
        switch(stat)
        {
            case pricelistMemberZusatzinfoType.NONE: 
                retVal = this.$t('ENUMS.PRICELISTMEMBERZUSATZINFOTYPE.NONE');
                break;
            case pricelistMemberZusatzinfoType.RALCARD: 
                retVal = this.$t('ENUMS.PRICELISTMEMBERZUSATZINFOTYPE.RALCARD');
                break; 
            case pricelistMemberZusatzinfoType.RALSTANDARD: 
                retVal = this.$t('ENUMS.PRICELISTMEMBERZUSATZINFOTYPE.RALSTANDARD');
                break;                                                                                            
        }
        return retVal;
    },
    GetOrientationText(stat)
    {
        var retVal = "";
        switch(stat)
        {
            case orientation.HORIZONTAL: 
                retVal = this.$t('ENUMS.ORIENTATION.HORIZONTAL');
                break;
            case orientation.VERTICAL: 
                retVal = this.$t('ENUMS.ORIENTATION.VERTICAL');
                break;                                                                                            
        }
        return retVal;
    },
    GetOrientationForSelect()
    {
        var ret = [];
        for(var i = 0; i < orientation.MAX; i++)
        {
            ret.push({"text": this.GetOrientationText(i), "value":i});
        }
        return ret;
    },
    GetPaymentTypeText(stat)
    {
        var retVal = "";
        switch(stat)
        {
            case paymentType.INCOMING: 
                retVal = this.$t('ENUMS.PAYMENTTYPE.INCOMING');
                break;
            case paymentType.OUTGOING: 
                retVal = this.$t('ENUMS.PAYMENTTYPE.OUTGOING');
                break;                                                                                            
        }
        return retVal;
    },
    GetPaymentTypeForSelect()
    {
        var ret = [];
        for(var i = 0; i < paymentType.MAX; i++)
        {
            ret.push({"text": this.GetPaymentTypeText(i), "value":i});
        }
        return ret;
    },
    GetTimeRecTypeText(stat)
    {
        var retVal = "";
        switch(stat)
        {
            case timeRecType.TIMEREC: 
                retVal = this.$t('ENUMS.TIMERECTYPE.TIMEREC');
                break;
            case timeRecType.PAUSE: 
                retVal = this.$t('ENUMS.TIMERECTYPE.PAUSE');
                break;   
            case timeRecType.VACATION: 
                retVal = this.$t('ENUMS.TIMERECTYPE.VACATION');
                break;
            case timeRecType.HOLIDAY: 
                retVal = this.$t('ENUMS.TIMERECTYPE.HOLIDAY');
                break;
            case timeRecType.SPECIAL: 
                retVal = this.$t('ENUMS.TIMERECTYPE.SPECIAL');
                break;   
            case timeRecType.ILL: 
                retVal = this.$t('ENUMS.TIMERECTYPE.ILL');
                break;
            case timeRecType.DOCTOR: 
                retVal = this.$t('ENUMS.TIMERECTYPE.DOCTOR');
                break;
            case timeRecType.OTHERS: 
                retVal = this.$t('ENUMS.TIMERECTYPE.OTHERS');
                break;                                
        }
        return retVal;
    },
    GetTimeRecTypeForSelect()
    {
        var ret = [];
        for(var i = 0; i < timeRecType.MAX; i++)
        {
            ret.push({"text": this.GetTimeRecTypeText(i), "value":i});
        }
        return ret;
    },
    GetSearchResultTypeText(stat)
    {
        var retVal = "";
        switch(stat)
        {
            case searchResultType.DEBITOR: 
                retVal = this.$t('ENUMS.SEARCHRESULTTYPE.DEBITOR');
                break;
            case searchResultType.DEBITOR_CONTACT: 
                retVal = this.$t('ENUMS.SEARCHRESULTTYPE.DEBITOR_CONTACT');
                break;                
            case searchResultType.DEBITOR_OBJ: 
                retVal = this.$t('ENUMS.SEARCHRESULTTYPE.DEBITOR_OBJ');
                break;   
            case searchResultType.CREDITOR: 
                retVal = this.$t('ENUMS.SEARCHRESULTTYPE.CREDITOR');
                break;
            case searchResultType.USER: 
                retVal = this.$t('ENUMS.SEARCHRESULTTYPE.USER');
                break;
            case searchResultType.OFFER: 
                retVal = this.$t('ENUMS.SEARCHRESULTTYPE.OFFER');
                break;   
            case searchResultType.ORDER: 
                retVal = this.$t('ENUMS.SEARCHRESULTTYPE.ORDER');
                break;
            case searchResultType.INVOICE: 
                retVal = this.$t('ENUMS.SEARCHRESULTTYPE.INVOICE');
                break;
            case searchResultType.PAYMENT: 
                retVal = this.$t('ENUMS.SEARCHRESULTTYPE.PAYMENT');
                break;                                
        }
        return retVal;
    }
}